import React from "react";
import "./Contacto.css";
import { FaWhatsapp } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { RiMailSendLine } from "react-icons/ri";
import { FaMapMarkedAlt } from 'react-icons/fa';

const Contacto = () => {
  return (
    <div className="bloquecontacto">
      <h1 className="contacto_title">Contactanos</h1>
      <div class="grid-container">
        <div className="contacto_subtitle"><FaWhatsapp size={50} className={"logos"} /> +54 9 3874586940</div>
        <div className="contacto_subtitle"><FaFacebookSquare size={50} className={"logos"} /> Trackline</div>
        <div className="contacto_subtitle"><RiMailSendLine size={50} className={"logos"} /> contactotrackline@gmail.com</div>
        <div className="contacto_subtitle"><FaMapMarkedAlt size={50} className={"logos"} /> Salta, Argentina</div>
      </div>
    </div>
  );
};

export default Contacto;