import "./App.css";
import travel_01 from "./assets/travel-01.jpg";
import travel_02 from "./assets/travel-02.jpg";
import travel_03 from "./assets/travel-03.jpg";
import imagen_04 from "./assets/imagen-04.png";
import imagen_05 from "./assets/imagen-05.jpg";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Slider from "./components/Slider";
import Contacto from "./components/Contacto";

function App() {
  const navbarLinks = [
    { url: "https://trackline.gpsgate.com", title: "Acceso Plataforma" },
  ];

  const listadoplat = [
    <div>
    <li><strong>Flota 360​​.</strong> Sepa lo que está sucediendo con la flota completa de un vistazo.</li>
    <li><strong>Estado individual.</strong> Profundice hasta los detalles más específicos del vehículo.</li>
    <li><strong>Notificaciones de evento.</strong> Entérate de las velocidades registradas, paradas y comportamientos que necesites saber de la manera más cómoda para tu uso.</li>
    <li><strong>Automático.</strong> Recibí toda la información que necesitas sin necesidad de estar en línea todo el tiempo.</li>
    <li><strong>Informe de siniestro.</strong> Realizamos por vos un relevamiento preciso de la información obtenida del evento para que tengas una conclusión basada en el triángulo de la seguridad vial.</li>
    </div>
    ];
    
    const listadoapp = [
      <div>
      <li><strong>Notificaciones.</strong> Enterate de todo lo que ocurre en tiempo real.</li>
      <li><strong>Recorridos históricos.</strong> Accede a toda la informacion generada desde cualquier lugar.</li>
      <li><strong>Compartir ubicación.</strong> Envia donde se encuentran tus unidades de multiples formas a tus contactos con periodos de tiempos personalizados.</li>
      <li><strong>Comandos.</strong> Tenes funciones especiales en tus unidades? Ejecutalas desde la app sin problemas.</li>
        </div>
      ];

      const listadopromociones = [
        <div>
        <li><strong> Nueva unidad.</strong> Por cada unidad nueva tenes un 20% de descuento en su mensualidad por 3 meses.</li>
        <li><strong>Descuentos por cantidad.</strong> Conecta todas tus unidades y empezá a disfrutar beneficios a partir de los 5 vehículos.</li>
        <li><strong>Programa de referidos. </strong> Invita a tus contactos y obtene junto a ellos descuentos por clientes nuevos en mensualidades e instalaciones.</li>
        <li><strong>Partners.</strong> Incorpora a tus unidades servicios relacionados a nuestros partners y obtene bonificaciones extra.</li>
          </div>
        ];

  return (
    <div className="App" >
      <Navbar navbarLinks={navbarLinks} />
      <Hero imageSrc={travel_01} />

        <Slider
          imageSrc={travel_02}
          title={"Nosotros"}
          subtitle={
            "Nos dedicamos al servicio de seguimiento satelital en tiempo real para la gestión de datos de flotas vehiculares y personas. Adaptándonos de la mejor forma a las necesidades del cliente logrando otorgar información valiosa de forma dinámica."
          }
        />

        <Slider
          imageSrc={imagen_04}
          title={"Plataforma web"}
          subtitle={"Contamos con la plataforma más avanzada para el segmento de IoT (Internet de las cosas) enfocada al seguimiento satelital de activos con disponibilidad las 24hs. Con ella logramos:"}
          text={listadoplat}
          flipped={true}
        />

        <Slider
          imageSrc={travel_03}
          title={"Aplicacion (Android/iOS)"}
          subtitle={"Nuestra aplicación no es solo una pantalla web en miniatura, está optimizada para brindar a los propietarios una descripción general de la flota, además de la capacidad de ver información y viajes de un solo vehículo. La aplicación de Trackline ayuda a mantenerte informado en todo momento. Tambien tenes:"}
          text={listadoapp}
        />

        <Slider
          imageSrc={imagen_05}
          title={"Promociones"}
          subtitle={"Con nuestro servicio siempre buscamos la forma de potenciar nuestros clientes y por eso también tenemos increíbles descuentos, ¡conocelos!."}
          text={listadopromociones}
          flipped={true}
        />

      <Contacto />
    </div>
  );
}

export default App;
